import React from 'react';
import './App.css';
import { Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LicenseInfo } from '@mui/x-license-pro';
import { Provider } from 'react-redux';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import AuthProvider from './contexts/AuthProvider';
import Register from './pages/spot-registration';
import BacklogRegister from './pages/register';
import PSTUConfig from './pages/admin-user/settings/pstu-configuration';
import HomePage from './pages/home';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import Success from './pages/paypal-checkout/success';
import Cancel from './pages/paypal-checkout/cancel';
import store from './store/reduxstore';
import { NavigateRoutes } from './constant';
// import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Admin from './pages/admin-user';
import StudentsLogs from './pages/admin-user/student-logs';
import RegionManagerLogs from './pages/admin-user/region-manager/view-logs';

import StudentsInfo from './pages/admin-user/students/students';
import StudentDashboard from './pages/student-dashboard';
import LocationCoordinatorDashboard from './pages/location-coordinator/dashboard';
import RegistrationDates from './pages/admin-user/registration-dates';
import TeacherResourcesInfo from './pages/admin-user/resources/teacher-resoureces';
import StudentResourcesInfo from './pages/admin-user/resources/student-resoureces';
import LocationStudentResource from './pages/location-coordinator/resources/student-resoureces';
import LocationTeacherResource from './pages/location-coordinator/resources/teacher-resoureces';
import BulkEmail from './pages/common/bulk-email';
import TeachersLCEmail from './pages/common/teachers-lc-email';
import Classes from './pages/location-coordinator/classes';
import Students from './pages/location-coordinator/students';
import Announcements from './pages/location-coordinator/announcements';
import RCAnnouncements from './pages/admin-user/announcements';
import SectionSync from './pages/location-coordinator/section-sync';
import MarksSync from './pages/location-coordinator/marks-sync';
// import LocationHelpSite from './pages/location-coordinator/help-site';
import HelpSite from './pages/admin-user/help-site';
import UserManager from './pages/admin-user/user-manager';
import RoleManager from './pages/admin-user/role-manager/index';
import CourseManager from './pages/admin-user/course-manager';
import ExamCenterManager from './pages/admin-user/exam-center-manger/index';
import AdminDashboard from './pages/admin-user/dashboard';
import HomeworkAssignments from './pages/admin-user/academic-panel';
import AcademicSchema from './pages/admin-user/academic-panel/Scheme/schema';
import HomeworkSchema from './pages/admin-user/homework-panel/homewor-panel-schema';
import QuizSchema from './pages/admin-user/quiz-schema';
import AssignmentsSchema from './pages/admin-user/assignments-schema';
import ActivitiesSchema from './pages/admin-user/co-curricular-activities-schema';
import LocationStudentDashboard from './pages/location-coordinator/location-student-dashboard';
import TeacherUser from './pages/teacher-user/index';
import TeacherAttendanceView from './pages/teacher-user/attendance/index';
import MyClass from './pages/teacher-user/my-class/index';
import TeacherSyncView from './pages/teacher-user/sync/index';
import RegionManager from './pages/admin-user/region-manager';
import LocationManager from './pages/admin-user/location-manager';
import AcademicDates from './pages/admin-user/academic-dates';
import Grading from './pages/admin-user/settings/grading';
import UnregisteredStudents from './pages/admin-user/settings/unregistered_students';
import WelcomeEmail from './pages/admin-user/settings/welcome-email';
import SettingsTermsAndCondition from './pages/admin-user/settings/terms-and-conditions';
import TeacherDashboard from './pages/teacher-user/dashboard';
import ExamCenterLogs from './pages/admin-user/examcenter-audit-logs';
import LocationManagerLogs from './pages/admin-user/location-manager/view-logs';
import CourseManagerLogs from './pages/admin-user/course-manager/view-logs';
import ExportedSheet from './pages/admin-user/exported-sheet';
import RoleManagerLogs from './pages/admin-user/role-manager-logs';
import Locations from './pages/locations';
import NotFound from './pages/error-404';
import Donate from './pages/website-donation';

LicenseInfo.setLicenseKey(
  'cd62fd50b491064711bfa863865f7013Tz00Mzg0MSxFPTE2ODQzMzM5MTg5MTYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        popper: {
          marginTop: '-0.8vw !important',
        },
        tooltip: {
          fontSize: '0.7vw !important',
          maxWidth: '100% !important',
        },
      },
    },
  },
});
function App() {
  const clientId = {
    'client-id':
      'AZQsHE-2a8oeGidH-G9jHtcEXkgexQU-lG0gBfnZO763Mi2lhS_cRTxEP7YfkLspzIsZCvekK5SNfqJD',
  };
  return (
    <Provider store={store}>
      <ToastContainer position="top-right" autoClose={5000} />
      <Router>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <Box
                style={{
                  background: '#E4F5FD',
                  minHeight: 'calc(100vh)',
                  margin: 'auto',
                }}
              >
                <Routes>
                  <Route path="*" element={<NotFound />} />

                  <Route path="/" exact element={<HomePage />} />
                  <Route
                    path={NavigateRoutes.LOCATION_COORDINATOR_VIEW}
                    element={<LocationCoordinatorDashboard />}
                  >
                    <Route
                      path={NavigateRoutes.LOCATION_COORDINATOR_EMAIL}
                      element={<BulkEmail />}
                    />
                    <Route
                      path={NavigateRoutes.LOCATION_COORDINATOR_TEACHERS_EMAIL}
                      element={<TeachersLCEmail />}
                    />
                    <Route
                      path={NavigateRoutes.LC_CLASSES}
                      element={<Classes />}
                    />
                    <Route
                      path={NavigateRoutes.LC_STUDENTS}
                      element={<Students />}
                    />
                    <Route
                      path={NavigateRoutes.LC_ANNOUNCEMENTS}
                      element={<Announcements />}
                    />
                    <Route
                      path={NavigateRoutes.LC_SECTION_SYNC}
                      element={<SectionSync />}
                    />
                    <Route
                      path={NavigateRoutes.LC_MARKS_SYNC}
                      element={<MarksSync />}
                    />
                    <Route
                      path={NavigateRoutes.LOCATION_COORDINATOR_TEACHERS_RESOURCES}
                      element={<LocationTeacherResource />}
                    />
                    <Route
                      path={NavigateRoutes.LOCATION_COORDINATOR_STUDENTS_RESOURCES}
                      element={<LocationStudentResource />}
                    />
                    <Route
                      path={NavigateRoutes.LOCATION_COORDINATOR_STUDENTS_DASHBOARD}
                      element={<LocationStudentDashboard />}
                    />
                    <Route
                      path={NavigateRoutes.LOCATION_COORDINATOR_HELP_SITE}
                      exact
                      element={<HelpSite />}
                    />
                  </Route>
                  <Route path={NavigateRoutes.DASHBOARD} element={<Admin />}>
                    <Route
                      path={NavigateRoutes.STUDENTS_VIEW}
                      element={<StudentsInfo />}
                    />
                    <Route
                      path={NavigateRoutes.RC_ANNOUNCEMENTS}
                      element={<RCAnnouncements />}
                    />
                    <Route
                      path={NavigateRoutes.STUDENTS_LOGS}
                      element={<StudentsLogs />}
                    />
                    <Route
                      path={NavigateRoutes.EXAMCENTER_LOGS}
                      element={<ExamCenterLogs />}
                    />
                    <Route
                      path={NavigateRoutes.LOCATION_MANAGER_LOGS}
                      element={<LocationManagerLogs />}
                    />
                    <Route
                      path={NavigateRoutes.COURSE_MANAGER_LOGS}
                      element={<CourseManagerLogs />}
                    />
                    <Route
                      path={NavigateRoutes.GET_REGION_MANAGER_LOGS}
                      element={<RegionManagerLogs />}
                    />
                    <Route
                      path={NavigateRoutes.ADMIN_EMAIL}
                      element={<BulkEmail />}
                    />
                    <Route
                      path={NavigateRoutes.REGISTRATION_DATES}
                      exact
                      element={<RegistrationDates />}
                    />
                    <Route
                      path={NavigateRoutes.ACADEMIC_DATES}
                      exact
                      element={<AcademicDates />}
                    />
                    <Route
                      path={NavigateRoutes.GRADING}
                      exact
                      element={<Grading />}
                    />
                    <Route
                      path={NavigateRoutes.SETTING_TERMS_AND_CONDITIONS}
                      exact
                      element={<SettingsTermsAndCondition />}
                    />
                    <Route
                      path={NavigateRoutes.BACKLOGREGISTER}
                      exact
                      element={<BacklogRegister />}
                    />
                    <Route
                      path={NavigateRoutes.UNREGISTERED_STUDENTS}
                      exact
                      element={<UnregisteredStudents />}
                    />
                    <Route
                      path={NavigateRoutes.WELCOME_EMAIL}
                      exact
                      element={<WelcomeEmail />}
                    />
                    <Route
                      path={NavigateRoutes.PSTU_CONFIG}
                      exact
                      element={<PSTUConfig />}
                    />
                    <Route
                      path={NavigateRoutes.HELP_SITE}
                      exact
                      element={<HelpSite />}
                    />
                    <Route
                      path={NavigateRoutes.EMAIL}
                      exact
                      element={<BulkEmail />}
                    />
                    <Route
                      path={NavigateRoutes.USER_MANAGER_VIEW}
                      element={<UserManager />}
                    />
                    <Route
                      path={NavigateRoutes.LOCATION_MANAGER_VIEW}
                      element={<LocationManager />}
                    />
                    <Route
                      path={NavigateRoutes.ROLE_MANAGER_VIEW}
                      element={<RoleManager />}
                    />
                    <Route
                      path={NavigateRoutes.ROLE_MANAGER_LOGS}
                      element={<RoleManagerLogs />}
                    />
                    <Route
                      path={NavigateRoutes.COURSE_MANAGER_VIEW}
                      element={<CourseManager />}
                    />
                    <Route
                      path={NavigateRoutes.EXAM_CENTER_MANAGER_VIEW}
                      element={<ExamCenterManager />}
                    />
                    <Route
                      path={NavigateRoutes.REGION_MANAGER_VIEW}
                      element={<RegionManager />}
                    />
                    <Route
                      path={NavigateRoutes.HOMEWORK_ASSIGNMENTS}
                      element={<HomeworkAssignments />}
                    />
                    <Route
                      path={NavigateRoutes.ACADEMIC_PANEL_SCHEMA}
                      element={<AcademicSchema />}
                    />
                    <Route
                      path={NavigateRoutes.HOMEWORK_SCHEMA}
                      element={<HomeworkSchema />}
                    />
                    <Route
                      path={NavigateRoutes.QUIZ_SCHEMA}
                      element={<QuizSchema />}
                    />
                    <Route
                      path={NavigateRoutes.ASSIGNMENTS_SCHEMA}
                      element={<AssignmentsSchema />}
                    />
                    <Route
                      path={NavigateRoutes.CURRICULAR_ACTIVITIES_SCHEMA}
                      element={<ActivitiesSchema />}
                    />
                    <Route
                      path={NavigateRoutes.ADMIN_DASHBOARD}
                      element={<AdminDashboard />}
                    />
                    <Route
                      path={NavigateRoutes.EXPORTED_SHEET}
                      element={<ExportedSheet />}
                    />
                  </Route>
                  <Route
                    path={NavigateRoutes.STUDENT_DASHBOARD}
                    exact
                    element={<StudentDashboard />}
                  />
                  <Route path={NavigateRoutes.DASHBOARD} element={<Admin />}>
                    <Route
                      path={NavigateRoutes.TEACHERS_RESOURCES}
                      element={<TeacherResourcesInfo />}
                    />
                  </Route>
                  <Route path={NavigateRoutes.DASHBOARD} element={<Admin />}>
                    <Route
                      path={NavigateRoutes.STUDENTS_RESOURCES}
                      element={<StudentResourcesInfo />}
                    />
                  </Route>

                  <Route path={NavigateRoutes.LOGIN} exact element={<Login />} />
                  <Route path={NavigateRoutes.LOCATIONS} exact element={<Locations />} />
                  <Route
                    path={NavigateRoutes.FORGOT_PASSWORD}
                    exact
                    element={<ForgotPassword />}
                  />
                  <Route
                    path={NavigateRoutes.RESET_PASSWORD}
                    exact
                    element={<ResetPassword />}
                  />
                  <Route
                    path={NavigateRoutes.PAYPAL_SUCCESS}
                    element={(
                      <PayPalScriptProvider
                        options={clientId}
                      >
                        <Success />
                      </PayPalScriptProvider>
                    )}
                    exact
                  />
                  <Route
                    path={NavigateRoutes.PAYPAL_CANCEL}
                    element={(
                      <PayPalScriptProvider
                        options={clientId}
                      >
                        <Cancel />
                      </PayPalScriptProvider>
                    )}
                    exact
                  />
                  <Route
                    path={NavigateRoutes.REGISTER}
                    exact
                    element={<Register />}
                  />
                  <Route
                    path={NavigateRoutes.DONATE}
                    exact
                    element={<Donate />}
                  />

                  <Route path={NavigateRoutes.TEACHER_VIEW} element={<TeacherUser />}>
                    <Route path={NavigateRoutes.TEACHER_ATTECNDANCE_VIEW} element={<TeacherAttendanceView />} />
                    <Route path={NavigateRoutes.MY_CLASS} element={<MyClass />} />
                    <Route path={NavigateRoutes.TEACHER_DASHBOARD} element={<TeacherDashboard />} />
                    <Route path={NavigateRoutes.TEACHER_SYNC_VIEW} element={<TeacherSyncView />} />
                    <Route
                      path={NavigateRoutes.TEACHER_VIEW_EMAIL}
                      element={<BulkEmail />}
                    />
                    <Route path={NavigateRoutes.TEACHER_PANEL_STUDENTS_RESOURCES} element={<LocationStudentResource />} />
                    <Route path={NavigateRoutes.TEACHER_PANEL_TEACHERS_RESOURCES} element={<LocationTeacherResource />} />
                  </Route>
                </Routes>
              </Box>
            </AuthProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Router>
    </Provider>
  );
}

export default App;
